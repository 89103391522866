import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import * as PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout"
import CheckoutStepper, { CheckoutStep } from "../components/CheckoutStepper"
import {connect} from "react-redux"
import { basketActions, basketSelectors } from '../state/basket'
import * as styles from "./finance-checkout-data.module.scss"
import HomeDelivery from "./homeDelivery"
import ClickAndCollect from "../components/BasketUI/ClickAndCollect"
import { createDisplayPrice } from "../utils/productsHelper"
import DeliveryDate from "../components/UI/DeliveryDate/DeliveryDate"
import {financeSelectors} from "../state/finance";
import {dataLayerUpdate} from "../../src/api/dataLayer/gaDataLayer";

const CheckoutFinanceDataPage = ({ location, removeShippingFee, addShippingFee, product }) => {
  dataLayerUpdate('finance_data_delivery', product)

  const isShippingEnabled = Boolean(Number(process.env.ENABLE_SHIPPING_FEE))
  const DELIVERY = 'delivery'
  const CLICKANDCOLLECT = 'clickAndCollect'
  const [deliveryOption, setDeliveryOption] = useState(CLICKANDCOLLECT)

  let isHomeDelivery = deliveryOption === DELIVERY
  let isClickAndCollect = deliveryOption === CLICKANDCOLLECT
  useEffect(() => {
    removeShippingFee()
  }, [])

  const handleChange = e => {
    const { id } = e.target
    if (id !== deliveryOption) setDeliveryOption(id)
    id !== CLICKANDCOLLECT
    ? addShippingFee()
    : removeShippingFee()
  }


  return (
    <Layout
      staticSeo={{ seoTitle: "Checkout finance", seoDescription: "Checkout finance process" }}
      location={{ pathname: location.pathname }}
    >
      <CheckoutStepper activeStepNumber={3}>
        <CheckoutStep pageLink={"/finance-checkout-product"} title="Choose a bike" />
        <CheckoutStep pageLink={"/finance-checkout-accessories"} title="Choose accessories" />
        <CheckoutStep pageLink={location.pathname} title="Choose delivery method" />
        <CheckoutStep pageLink={"/finance-checkout-summary"} title="Finish" />
      </CheckoutStepper>
      <Container className="my-5">
        <Row className="justify-content-center justify-content-md-start">
          <Col xs={12} >
            <h3 className="pb-4">
              Please select your preferred delivery method
            </h3>
            <p className="m-0 mb-5">
              You can select your preferred delivery method and we'll take care
              of the rest.
            </p>
          </Col>
          <Row className="d-flex flex-column flex-md-row">
            <Col
              className={`px-0  m-2 p-3 p-lg-0 ${
                styles.deliveryOptionContainer
              } ${isHomeDelivery && "border-dark"}`}>
              <div
                className={`d-flex form-group align-content-center form-check `}>
                <div className="pl-4 pr-2 d-flex align-items-center">
                  <input
                    type="checkbox"
                    className={` form-check-input`}
                    id={DELIVERY}
                    required
                    checked={deliveryOption === DELIVERY}
                    onClick={handleChange}
                  />
                </div>
                <div className="d-flex align-content-center pt-2 pt-lg-0">
                  <StaticImage
                    src="../../src/images/icon-delivery.png"
                    alt="Delivery van"
                    width={100}
                    className="mr-3"
                    placeholder="none"
                    objectFit="scale-down"
                  />
                  <label
                    className={`d-flex align-items-center h3 mb-0 text-uppercase ${
                      isHomeDelivery
                        ? styles.selectedDeliveryOptionText
                        : styles.deliveryOptionText
                    }`}>
                    delivery
                  </label>
                </div>
                <span
                  className={`h4 mt-2 text-uppercase position-absolute ${
                    isHomeDelivery
                      ? styles.selectedDeliveryOptionText
                      : styles.deliveryOptionText
                  }`}>
                  { isShippingEnabled ? createDisplayPrice(process.env.SHIPPING_FEE, "finance-checkout-data") : "FREE"}
                </span>
              </div>
            </Col>

            <Col

              className={`px-0  m-2 p-3 p-lg-0 ${
                styles.deliveryOptionContainer
              } ${isClickAndCollect && "border-dark"} `}>
              <div
                className={`d-flex form-group align-content-center form-check`}>
                <div className="pl-4 pr-2 d-flex align-items-center">
                  <input
                    type="checkbox"
                    className={` form-check-input`}
                    id={CLICKANDCOLLECT}
                    required
                    checked={deliveryOption === CLICKANDCOLLECT}
                    onClick={handleChange}
                  />
                </div>
                <div className="d-flex align-content-center pt-2 pt-lg-0">
                  <StaticImage
                    src="../../src/images/icon-clickandcollect.png"
                    alt="Click and collect"
                    width={80}
                    className="mr-3"
                    placeholder="none"
                    objectFit="scale-down"
                  />
                  <label
                    className={`d-flex align-items-center h3 mb-0 text-uppercase ${
                      isClickAndCollect
                        ? styles.selectedDeliveryOptionText
                        : styles.deliveryOptionText
                    }`}>
                    click & collect
                  </label>
                </div>
                <span
                  className={`h4 mt-2 text-uppercase position-absolute ${
                    isClickAndCollect
                      ? styles.selectedDeliveryOptionText
                      : styles.deliveryOptionText
                  }`}>
                  free
                </span>
              </div>
            </Col>
          </Row>
        </Row>
        <Row>
          <Col xs={12} className="p-lg-0">
          <DeliveryDate deliveryOption={deliveryOption} />
            <HomeDelivery
              financeCheckout
              isSelected={deliveryOption === DELIVERY}/>
            <ClickAndCollect
              financeCheckout
              isSelected={deliveryOption === CLICKANDCOLLECT}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <hr className="my-3 my-lg-5" />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const mapStateToProps = state => ({
  product: financeSelectors.getProduct(state),
  isShippingSelected: basketSelectors.isShippingSelected(state),
  getPersonalDetails: basketSelectors.getDeliveryOptionDetails(state)
});

const mapDispatchToProps = {
  addShippingFee: basketActions.addShippingFee,
  removeShippingFee: basketActions.removeShippingFee,
};


export default connect(mapStateToProps, mapDispatchToProps) (CheckoutFinanceDataPage)
